<template>
  <div class="tools" id="tools">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#E63D36"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h3>Tools Management</h3>
        <router-link to="/addTool">+Add</router-link>
      </div>
      <div class="box">
        <perfect-scrollbar class="filter-box">
          <div class="products-buttons">
            <button v-for="product,index in products" :key="product.id" :data-id="product.id"
                    :class="index == 0?'btn btn-default filter-button active':'btn btn-default filter-button'"
                    :data-filter="product.name.replace(/[^a-zA-Z0-9]/g, '')">
              {{ product.name }}
            </button>
          </div>
        </perfect-scrollbar>
        <perfect-scrollbar class="inner-box">
          <div class="data">
            <div v-for="tool in tools" :key="tool.id"
              :class="'card filter ' + tool.product.name.replace(/[^a-zA-Z0-9]/g, '')"
              :style="tool.product.name != first_product_name?'display: none;':''"
              :data-id="tool.id"
              :data-product="tool.product.id" class="card"
              >
              <div class="head">
                <h5>{{ tool.name }}</h5>
                <div class="icons">
                  <img class="edit-btn" src="@/assets/icons/edit.png" alt="Edit Icon">
                  <img class="delete-btn" src="@/assets/icons/delete.png"
                    data-bs-toggle="modal" data-bs-target="#deletModal" alt="Delete Icon">
                </div>
              </div>
              <form>
                <hr>
                <div class="row">
                  <div class="col-md-12">
                    <label>Product</label>
                    <select disabled>
                      <option :value="tool.product.id" selected>{{ tool.product.name }}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label>Icon</label>
                    <label for="iconUpload" class="upload-icon">Icon Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon"/>
                    </label>
                    <input type="file" name="icon" id="iconUpload" accept="image/*" style="display: none"/>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <label>Name</label>
                        <input type="text" name="name" placeholder="Name" :value="tool.name"/>
                      </div>
                    </div>
                  </div>
                </div>   
                <div class="error-message" :id="'errors' + tool.id">
                  <!--  -->
                </div>           
                <input type="submit" class="submit-btn" value="Submit" />
              </form>
            </div>

            <p v-if="noData" class="no-data">There Is No Data</p>

          </div>
        </perfect-scrollbar>
      </div>
    </div>

    <app-delete-modal modalTitle="Delete Tool"/>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import AppDeleteModal from '@/components/global/AppDeleteModal.vue'

export default {
  name: "ToolsView",
  data() {
    return {
      tools: [],
      products: [],
      deleteCardId: -1,
      deleteCardProduct: -1,
      first_product_name: '',
      noData: 0,
      isLoading: true,
    }
  },
  methods: {
    getProducts() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/products',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.data.data != []){
            this.products = data.data.data;
            this.products.forEach(product => {
              this.getTools(product);
            });
            this.noData = 0;
          }else{
            this.noData = 1;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getTools(product) {
      fetch(this.serverURL + 'api/products/' + product.id + '/tools', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          let array = data.data;
          array.forEach(el => {
            el["product"] = product;
            this.tools.push(el);
          });
        })
        .catch(error => {
          console.log(error);
        })
    },
    updateTool(product_id, tool_id, data) {
      fetch(this.serverURL + 'api/products/' + product_id + '/tools/' + tool_id,{
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: data,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 200){
            location.reload();
          }else{
            let messages = '';

            for(let propt in data.errors){
              messages += `<p>${data.errors[propt].join(', ')}</p>`;
            }
            window.$('#errors' + tool_id).empty().append(messages);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteTool(product_id, tool_id) {
      fetch(this.serverURL + 'api/products/' + product_id + '/tools/' + tool_id,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          location.reload();
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  beforeMount() {
    this.getProducts();
  },
  mounted(){
    let that = this;

    // On Form Submit
    window.$('body').on('submit', '.tools form', function(e){
      e.stopImmediatePropagation();
      e.preventDefault();
      // window.$(this).slideUp(300);

      let tool_id = window.$(this).parents('.card').data('id');
      let product_id = window.$(this).parents('.card').data('product');
      // console.log(product_id, tool_id);

      let inputs = window.$(this).find(':input[name]');
      let formData = new FormData();
      inputs.each(function() {
        if (this.type == 'file'){
          Array.from(this.files).forEach(el => {
            formData.append(this.name, el);
          });
        }
        else if (this.value != "")
          formData.append(this.name, this.value);
      });
      formData.append('_method', 'PUT');
      // console.log(Object.fromEntries(formData.entries()));

      that.updateTool(product_id, tool_id, formData);
    });

    // On Edit Button Click
    window.$('body').on('click', '.tools .edit-btn', function(e){
      e.stopImmediatePropagation();
      window.$(this).parent().parent().next().slideDown(300);
    });

    // On Delete Button Click
    window.$('body').on('click', '.tools .delete-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteCardId = window.$(this).parents('.card').data('id');
      that.deleteCardProduct = window.$(this).parents('.card').data('product');
    });

    // On Yes Button Click
    window.$('body').on('click', '.tools .yes-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteTool(that.deleteCardProduct, that.deleteCardId);
    });

    // On Services Buttons Clicked
    window.$("body").on('click', '.tools .filter-button', function (e) {
      e.stopImmediatePropagation();
      let value = window.$(this).data('filter');

      window
          .$(".tools .filter")
          .not("." + value)
          .hide("3000");
      window
          .$(".tools .filter")
          .filter("." + value)
          .show("3000");

      window.$(".tools .filter-button").removeClass("active");
      window.$(this).addClass("active");
    });
  },
  watch: {
    products: function() {
      this.first_product_name = this.products[0].name;
    }
  },
  components: {
    AppDeleteModal,
    Loading,
  },
};
</script>

<style>
.tools{
  background-color: #1E1E1E;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.tools .temp{
  min-width: 300px;
  transition: all .5s;
}
.close-rail .tools .temp{
  min-width: 75px;
  transition: all .5s;
}
/* Content */
.tools .content{
  position: relative;
  background-color: #F1F1F1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.tools .content .title{
  min-height: 11%;
  display: flex;
  justify-content: space-between;
}
.tools .content .title h3,
.tools .content .title a{
  font-weight: bold;
}
.tools .content .title a{
  text-decoration: none;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  box-shadow: 2px 2px 5px #CCC;
  color: #FFF;
  border-radius: 0.7rem;
  padding: .5rem 1.5rem;
  height: fit-content;
}
.tools .content .title a:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.tools .content .title a:focus{
  background-color: #FFF;
  color: #D01B50;
}
.tools .content .box{
  overflow: hidden;
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 1px 5px #CCC;
  padding: 2rem 1.5rem;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.tools .content .box .filter-box {
  max-height: 25%;
}
.tools .content .box .inner-box {
  margin-top: 1.5rem;
}
.tools .content .box .inner-box .data{
  padding-right: 1.5rem;
}
.tools .content .box .inner-box .ps__rail-y{
  border-radius: 1rem;
}
.tools .content .box .inner-box .ps__thumb-y{
  background-color: #D01B50;
}
/* Buttons */
.tools .content .products-buttons {
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-x: auto;
}
.tools .content .products-buttons::-webkit-scrollbar{
  display: none;
}
.tools .content .filter-button {
  background-color: #EAEAEA;
  text-align: center;
  border-radius: 0;
  color: #3C3C3C;
  border: none;
}
.tools .content .filter-button:hover,
.tools .content .filter-button.active {
  color: #FFF;
  background-color: #4C0002;
}
.tools .content .filter-button:not(:last-of-type) {
  border-right: 1px solid #4C000250;
}
/* card */
.tools .content .data .card{
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 1.3rem;
  color: black;
}
.tools .content .data .card .head{
  display: flex;
  justify-content: space-between;
}
.tools .content .data .card .head .icons img{
  width: 1.4rem;
  cursor: pointer;
}
.tools .content .data .card .head .icons img:last-child{
  margin-left: 1rem;
}
/* Form */
.tools .content .data .card form{
  display: none;
}
.tools .content .data .card form hr{
  background-color: #DCDCDC;
  opacity: 1;
  border: none;
  height: 1px;
  margin-bottom: 0;
}
.tools .content .data .card form label:not(.upload-icon){
  display: block;
  color: black;
  margin: 1rem 0 .5rem 0;
}
.tools .content .data .card form input,
.tools .content .data .card form label.upload-icon,
.tools .content .data .card form select{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 2rem;
  width: 90%;
}
.tools .content .data .card form select {
  background-color: transparent;
  outline: none;
  cursor: pointer;
  width: 40%;
}
.tools .content .data .card form option{
  color: #000000;
}
.tools .content .data .card form label.upload-icon{
  background-color: #EFEFEF;
  color: #6B6B6B;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: none;
}
.tools .content .data .card form label.upload-icon img{
  width: 1.2rem;
  height: 1.1rem;
}
.tools .content .data .card form .submit-btn{
  padding: .5rem 1rem;
  border-radius: 1rem;
  color: #FFF;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 6rem;
}
.tools .content .data .card form .submit-btn:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.tools .content .data .card form .submit-btn:focus{
  background-color: #FFF;
  color: #D01B50;
}
</style>