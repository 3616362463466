<template>
  <div class="header" id="header">
    <v-card>
      <v-layout>
        <v-navigation-drawer
          v-model="drawer"
          :rail="rail"
          permanent
          @click="openNavigationDrawer"
        >
          <div class="head" v-if="!rail">
            <div class="logo">
              <img src="@/assets/small-logo.png" alt="Logo Icon" />
              <v-btn
                variant="text"
                icon="mdi-chevron-left"
                @click.stop="closeNavigationDrawer"
              ></v-btn>
            </div>
            <v-divider></v-divider>
          </div>

          <v-list density="compact" nav>
            <v-list-item
              prepend-icon="mdi-home-city"
              title="Industry Management"
              value="industry"
              to="/Industry"
            >
            </v-list-item>
            <v-list-item
              prepend-icon="mdi-temple-buddhist"
              title="Service Management"
              value="service"
              to="/Service"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-scanner"
              title="Plans Management"
              value="plans"
              to="/Plans"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-apple-keyboard-option"
              title="Options Management"
              value="options"
              to="/Options"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-equalizer"
              title="Quality Management"
              value="quality"
              to="/Quality"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-equalizer"
              title="Process Management"
              value="process"
              to="/Process"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-store"
              title="Product Management"
              value="product"
              to="/Product"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-tools"
              title="Tools Management"
              value="tools"
              to="/Tools"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-feather"
              title="Features Management"
              value="features"
              to="/Features"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-cellphone-message"
              title="View Message"
              value="view"
              to="/Message"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-bell"
              title="Notifications"
              value="notifications"
              to="/Notification"
            ></v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main></v-main>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      drawer: true,
      rail: false,
    };
  },
  props: [],
  methods: {
    openNavigationDrawer(){
      let windowWidth = window.innerWidth;
      if (windowWidth > 767){
        this.rail = false;
        this.$emit('toggle-rail', this.rail);
      }
    },
    closeNavigationDrawer(){
      this.rail = true;
      this.$emit('toggle-rail', this.rail);
    },
  },
  mounted(){
    let that = this;

    // Init Rail Of App Header
    let windowWidth = window.innerWidth;
    if (windowWidth < 767){
      that.rail = true;
      that.$emit('toggle-rail', that.rail);
    }

    // On Resize Body
    window.$(window).on('resize', function(e){
      windowWidth = e.target.innerWidth;
      if (windowWidth < 767){
        that.rail = true;
        that.$emit('toggle-rail', that.rail);
      }
    });
  },
  components: {},
};
</script>

<style>
.header .v-navigation-drawer{
  background-color: #1E1E1E;
  color: #FFF;
  border: none;
  width: 300px !important;
  transition: all .5s;
}
.close-rail .header .v-navigation-drawer{
  width: 75px !important;
  transition: all .5s;
}
.header .v-navigation-drawer .head{
  height: 20%;
}
.header .v-navigation-drawer .v-list{
  overflow: auto;
  height: 80%;
}
.header .v-navigation-drawer .v-list::-webkit-scrollbar{
  display: none;
}
.close-rail .header .v-navigation-drawer .v-list{
  height: 100%;
}
.header  .v-navigation-drawer .logo{
  padding: 2rem 1rem 0 2rem;
  display: flex;
  justify-content: space-between;
}
.header  .v-navigation-drawer .logo img{
  width: 8rem;
}
.header .v-navigation-drawer .v-btn{
  margin-top: 1rem;
}
.header .v-navigation-drawer .v-list-item{
  padding: .5rem 2rem !important;
}
.close-rail .header .v-navigation-drawer .v-list-item{
  padding: .5rem 1rem !important;
}
.header .v-navigation-drawer .v-list-item-title{
  line-height: 3;
  font-size: .9rem;
  text-align: left;
}
</style>
