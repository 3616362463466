<template>
  <div class="send-notification" id="send-notification">
    <div class="temp"></div>
    <div class="content">
      <div class="title">
        <h3>Send Notification</h3>
      </div>
      <div class="box">
        <perfect-scrollbar class="inner-box">
          <div class="data">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <label>Email</label>
                  <input type="email" name="email" placeholder="Email" />
                </div>
                <div class="col-md-6 radio-group">
                  <div class="input-radio">
                    <input type="checkbox" name="all-users">
                    <label>Send To All Users</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <label>Subject</label>
                  <input type="text" name="subject" placeholder="Subject" />
                </div>
                <div class="col-md-12">
                  <label>Message</label>
                  <textarea name="message" placeholder="Message"></textarea>
                </div>
              </div>
              <input type="submit" class="submit-btn" value="Send" />
            </form>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  name: "SendNotificationView",
  props: [],
  methods: {},
  mounted(){
    // On Form Submit
    window.$('body').on('submit', '.send-notification form', function(e){
      e.preventDefault();
      console.log("Submit");
    });

    // On Checkbox Clicked
    window.$('body').on('click', '.send-notification form .input-radio input[type="checkbox"]', function() {
      let value = this.checked;
      if (value == true){
        window.$('input[type="email"]').val('').attr('disabled', true);
      }else{
        window.$('input[type="email"]').attr('disabled', false);
      }
    });
  },
  components: {},
};
</script>

<style>
.send-notification{
  background-color: #1E1E1E;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.send-notification .temp{
  min-width: 300px;
  transition: all .5s;
}
.close-rail .send-notification .temp{
  min-width: 75px;
  transition: all .5s;
}
/* Content */
.send-notification .content{
  background-color: #F1F1F1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.send-notification .content .title{
  min-height: 11%;
  display: flex;
  justify-content: space-between;
}
.send-notification .content .title h3{
  font-weight: bold;
}
.send-notification .content .box{
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 1px 5px #CCC;
  padding: 2rem 1.5rem;
  height: 90%;
}
.send-notification .content .box .inner-box{
  height: 100%;
}
.send-notification .content .box .inner-box .data{
  padding-right: 1.5rem;
}
.send-notification .content .box .inner-box .ps__rail-y{
  border-radius: 1rem;
}
.send-notification .content .box .inner-box .ps__thumb-y{
  background-color: #D01B50;
}
/* Form */
.send-notification .content .data form label{
  display: block;
  margin: 1rem 0 .5rem 0;
}
.send-notification .content .data form input{
  padding: .5rem 1rem;
  border-radius: 2rem;
  width: 90%;
  border: 1px solid #CCC;
}
.send-notification .content .data form .radio-group{
  display: flex;
  align-items: end;
}
.send-notification .content .data form .input-radio{
  display: flex;
  justify-content: flex-start;
  color: #6B6B6B;
}
.send-notification .content .data form .input-radio input[type="checkbox"]{
  margin-right: .5rem;
  width:fit-content;
  accent-color: #d01b50;
}
.send-notification .content .data form .input-radio label{
  margin: 0;
}
.send-notification .content .data form input[name="subject"]{
  width: 100%;
}
.send-notification .content .data form textarea{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 1rem;
  width: 100%;
}
.send-notification .content .data form .submit-btn{
  padding: .5rem 1rem;
  border-radius: 1rem;
  color: #FFF;
  background-color: #D01B50;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 100%;
}
</style>