import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Industry from '@/views/IndustryView.vue'
import addIndustry from '@/views/addIndustryView.vue'
import Service from '@/views/ServiceView.vue'
import addService from '@/views/addServiceView.vue'
import Options from '@/views/OptionsView.vue'
import Process from '@/views/ProcessView.vue'
import addProcess from '@/views/addProcessView.vue'
import addOption from '@/views/addOptionView.vue'
import Quality from '@/views/QualityView.vue'
import addQuality from '@/views/addQualityView.vue'
import Plans from '@/views/PlansView.vue'
import addPlan from '@/views/addPlanView.vue'
import Product from '@/views/ProductView.vue'
import addProduct from '@/views/addProductView.vue'
import Tools from '@/views/ToolsView.vue'
import addTool from '@/views/addToolView.vue'
import Features from '@/views/FeaturesView.vue'
import addFeature from '@/views/addFeatureView.vue'
import Message from '@/views/MessageView.vue'
import Notification from '@/views/NotificationView.vue'
import sendNotification from '@/views/sendNotificationView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/Industry',
    name: 'Industry',
    component: Industry
  },
  {
    path: '/addIndustry',
    name: 'addIndustry',
    component: addIndustry
  },
  {
    path: '/Service',
    name: 'Service',
    component: Service
  },
  {
    path: '/addService',
    name: 'addService',
    component: addService
  },
  {
    path: '/Options',
    name: 'Options',
    component: Options
  },
  {
    path: '/addOption',
    name: 'addOption',
    component: addOption
  },
  {
    path: '/Quality',
    name: 'Quality',
    component: Quality
  },
  {
    path: '/addQuality',
    name: 'addQuality',
    component: addQuality
  },
  {
    path: '/Plans',
    name: 'Plans',
    component: Plans
  },
  {
    path: '/addPlan',
    name: 'addPlan',
    component: addPlan
  },
  {
    path: '/Process',
    name: 'Process',
    component: Process
  },
  {
    path: '/addProcess',
    name: 'addProcess',
    component: addProcess
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product
  },
  {
    path: '/addProduct',
    name: 'addProduct',
    component: addProduct
  },
  {
    path: '/Tools',
    name: 'Tools',
    component: Tools
  },
  {
    path: '/addTool',
    name: 'addTool',
    component: addTool
  },
  {
    path: '/Features',
    name: 'Features',
    component: Features
  },
  {
    path: '/addFeature',
    name: 'addFeature',
    component: addFeature
  },
  {
    path: '/Message',
    name: 'Message',
    component: Message
  },
  {
    path: '/Notification',
    name: 'Notification',
    component: Notification
  },
  {
    path: '/sendNotification',
    name: 'sendNotification',
    component: sendNotification
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
