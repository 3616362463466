<template>
  <div class="add-product" id="add-product">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#E63D36"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h3>Add Product</h3>
      </div>
      <div class="box">
        <perfect-scrollbar class="inner-box">
          <div class="data">
            <form @submit.prevent="addProduct">
              <div class="row">
                <div class="col-md-6">
                  <label>Service</label>
                  <select v-model="productData.service_id" required>
                    <option v-for="service in services" :key="service.id" :value="service.id">{{ service.name }}</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Industry</label>
                  <select v-model="productData.industry_id" required>
                    <option v-for="industry in industries" :key="industry.id" :value="industry.id">{{ industry.name }}</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Product's View Image</label>
                  <label for="viewImage" class="upload-input"
                    >Image Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                  </label>
                  <input
                    type="file"
                    id="viewImage"
                    @change="handleProductViewChange"
                    accept="image/*"
                    style="display: none"
                  />
                </div>
                <div class="col-md-6">
                  <label>Product Image</label>
                  <label for="productImage" class="upload-input"
                    >Image Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                  </label>
                  <input
                    type="file"
                    id="productImage"
                    @change="handleProductImageChange"
                    accept="image/*"
                    style="display: none"
                  />
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <label>Name</label>
                      <input type="text" v-model="productData.en_name" placeholder="Name" required/>
                    </div>
                    <div class="col-md-12">
                      <label>Short Description</label>
                      <textarea
                        v-model="productData.en_short_description"
                        placeholder="Description"
                        required
                      ></textarea>
                    </div>
                    <div class="col-md-12">
                      <label>Full Description</label>
                      <textarea
                        v-model="productData.en_full_description"
                        placeholder="Description"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row ar">
                    <div class="col-md-12">
                      <label>الاسم</label>
                      <input type="text" v-model="productData.ar_name" placeholder="الاسم" required/>
                    </div>
                    <div class="col-md-12">
                      <label>الشرح المختصر</label>
                      <textarea
                        v-model="productData.ar_short_description"
                        placeholder="الوصف"
                        required
                      ></textarea>
                    </div>
                    <div class="col-md-12">
                      <label>الشرح الكامل</label>
                      <textarea
                        v-model="productData.ar_full_description"
                        placeholder="الوصف"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <label>Mobile And PC</label>
                  <div class="radio-group">
                    <div class="input-radio">
                      <input type="radio" v-model="productData.type" value="mobile" required>
                      <label>Mobile</label>
                    </div>
                    <div class="input-radio">
                      <input type="radio" v-model="productData.type" value="pc">
                      <label>PC</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Mobile Images</label>
                  <label for="mobileImages" class="upload-input"
                    >Image Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                  </label>
                  <input
                    type="file"
                    id="mobileImages"
                    @change="updateMobilePreview"
                    accept="image/*"
                    name="mobile_images[]"
                    style="display: none"
                    multiple
                  />
                  <div class="image-preview" id="mobile-preview">
                    <!--  -->
                  </div>
                </div>
                <div class="col-md-6">
                  <label>PC Images</label>
                  <label for="pcImages" class="upload-input"
                    >Image Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                  </label>
                  <input
                    type="file"
                    id="pcImages"
                    @change="updatePCPreview"
                    accept="image/*"
                    name="pc_images[]"
                    style="display: none"
                    multiple
                  />
                  <div class="image-preview" id="pc-preview">
                    <!--  -->
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Demo Link</label>
                  <input type="text" v-model="productData.demo_link" placeholder="https://" required/>
                </div>
                <div class="col-md-6">
                  <label>Price</label>
                  <input type="number" v-model="productData.price" placeholder="Price" required/>
                </div>
              </div>
              <div class="error-message">
                <p v-for="error in errorMessages" :key="error">{{ error }}</p>
              </div>
              <input type="submit" class="submit-btn" value="Submit" :disabled="isLoading ? true:false" />
            </form>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: "AddProductView",
  data() {
    return {
      services: [],
      industries: [],
      productData: {
        service_id: -1,
        industry_id: -1,
        en_name: '',
        ar_name: '',
        en_short_description: '',
        ar_short_description: '',
        en_full_description: '',
        ar_full_description: '',
        price: '',
        inside_image: null,
        outside_image: null,
        demo_link: '',
        pc_images: [],
        mobile_images: [],
      },
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    getServices() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/services',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          this.services = data.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getIndustries() {
      fetch(this.serverURL + 'api/industries',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          this.industries = data.data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    addProduct() {
      this.isLoading = true;

      console.log(this.productData);

      let formData = new FormData();
      formData.append("service_id", this.productData.service_id);
      formData.append("industry_id", this.productData.industry_id);
      formData.append("en[name]", this.productData.en_name);
      formData.append("ar[name]", this.productData.ar_name);
      formData.append("en[short_description]", this.productData.en_short_description);
      formData.append("ar[short_description]", this.productData.ar_short_description);
      formData.append("en[full_description]", this.productData.en_full_description);
      formData.append("ar[full_description]", this.productData.ar_full_description);
      formData.append("price", this.productData.price);
      formData.append("type", this.productData.type);
      formData.append("inside_image", this.productData.inside_image);
      formData.append("outside_image", this.productData.outside_image);
      formData.append("demo_link", this.productData.demo_link);
      Array.from(this.productData.mobile_images).forEach(el => {
        formData.append("mobile_images[]", el);
      });
      Array.from(this.productData.pc_images).forEach(el => {
        formData.append("pc_images[]", el);
      });

      fetch(this.serverURL + 'api/products',{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 201){
            this.$router.push({ name: "Product"});
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(', '));
            }
            this.errorMessages = messages;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
        })
    },
    handleProductImageChange(e) {
      const selectedFile = e.target.files[0];
      this.productData.inside_image = selectedFile;
    },
    handleProductViewChange(e) {
      const selectedFile = e.target.files[0];
      this.productData.outside_image = selectedFile;
    },
    updateMobilePreview: function () {
      let files = document.getElementById('mobileImages').files;
      this.productData.mobile_images = files;

      let result = '';
      window.$.each(files, function (key, file){
        result += `
          <div class="image">
            <i class="mobile-delete-icon delete-icon mdi mdi-cancel" data-id="${key}"></i>
            <img src="${URL.createObjectURL(file)}" alt="Mobile Image">
          </div>
        `;
      });
      window.$("#mobile-preview").empty().append(result);
    },
    updatePCPreview: function () {
      let files = document.getElementById('pcImages').files;
      this.productData.pc_images = files;

      let result = '';
      window.$.each(files, function (key, file){
        result += `
          <div class="image">
            <i class="pc-delete-icon delete-icon mdi mdi-cancel" data-id="${key}"></i>
            <img src="${URL.createObjectURL(file)}" alt="PC Image">
          </div>
        `;
      });
      window.$("#pc-preview").empty().append(result);
    },
  },
  beforeMount() {
    this.getServices();
    this.getIndustries();
  },
  mounted() {
    let that = this;

    // On Radio Input Clicked
    window.$('body').on('click', '.add-product .input-radio input[type="radio"]', function(e){
      e.stopImmediatePropagation();

      let value = this.value;
      if (value == 'mobile'){
        document.getElementById("mobileImages").disabled = false;
        document.getElementById("pcImages").disabled = true;
      }else{
        document.getElementById("pcImages").disabled = false;
        document.getElementById("mobileImages").disabled = true;
      }
    });

    // On Delete Mobile Image
    window.$('body').on('click', '.add-product .mobile-delete-icon', function(e){
      e.stopImmediatePropagation();

      let dt = new DataTransfer();
      let id = window.$(this).data('id');
      let input = document.getElementById('mobileImages');
      
      window.$.each(input.files, function (key, file){
        if (id !== key)
          dt.items.add(file);
      });
      input.files = dt.files;

      that.updateMobilePreview();
    });

    // On Delete PC Image
    window.$('body').on('click', '.add-product .pc-delete-icon', function(e){
      e.stopImmediatePropagation();

      let dt = new DataTransfer();
      let id = window.$(this).data('id');
      let input = document.getElementById('pcImages');

      window.$.each(input.files, function (key, file){
        if (id !== key)
          dt.items.add(file);
      });
      input.files = dt.files;

      that.updatePCPreview();
    });
  },
  components: {
    Loading,
  },
};
</script>

<style>
.add-product {
  background-color: #1e1e1e;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.add-product .temp {
  min-width: 300px;
  transition: all .5s;
}
.close-rail .add-product .temp {
  min-width: 75px;
  transition: all 0.5s;
}
/* Content */
.add-product .content {
  position: relative;
  background-color: #f1f1f1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.add-product .content .title {
  min-height: 11%;
}
.add-product .content .title h3{
  font-weight: bold;
}
.add-product .content .box {
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 5px #ccc;
  padding: 2rem 1.5rem;
  height: 90%;
}
.add-product .content .box .inner-box {
  height: 100%;
  overflow: auto;
}
.add-product .content .box .inner-box .data {
  padding-right: 1.5rem;
}
.add-product .content .box .inner-box .ps__rail-y {
  border-radius: 1rem;
}
.add-product .content .box .inner-box .ps__thumb-y {
  background-color: #d01b50;
}
/* Form */
.add-product .content .data form .ar{
  direction: rtl;
  text-align: right;
}
.add-product .content .data form select {
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.add-product .content .data form label {
  display: block;
  margin: 1rem 0 .5rem 0;
}
.add-product .content .data form input,
.add-product .content .data form label.upload-input,
.add-product .content .data form select {
  border: 1px solid #CCC;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  width: 90%;
}
.add-product .content .data form label.upload-input {
  border: none;
  background-color: #efefef;
  color: #6b6b6b;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.add-product .content .data form label.upload-input img {
  width: 1.2rem;
  height: 1.1rem;
}
.add-product .content .data form textarea {
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  width: 100%;
}
.add-product .content .data form .radio-group{
  display: flex;
  justify-content: flex-start;
}
.add-product .content .data form .radio-group .input-radio{
  display: flex;
  justify-content: flex-start;
  color: #6B6B6B;
}
.add-product .content .data form .radio-group .input-radio:not(:last-child){
  margin-right: 1rem;
}
.add-product .content .data form .radio-group .input-radio input[type="radio"]{
  margin-right: .5rem;
  accent-color: #d01b50;
}
.add-product .content .data form .image-preview{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 1rem;
}
.add-product .content .data form .image-preview .image{
  position: relative;
  width: 5rem;
  margin-bottom: .3rem;
}
.add-product .content .data form .image-preview .image img{
  width: 100%;
  height: 100%;
}
.add-product .content .data form .image-preview .image .delete-icon{
  position: absolute;
  top: -5px;
  right: 0;
  cursor: pointer;
}
.add-product .content .data form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  color: #fff;
  border: 2px solid #D01B50;
  background-color: #d01b50;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 6rem;
}
.add-product .content .data form .submit-btn:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.add-product .content .data form .submit-btn:focus{
  background-color: #FFF;
  color: #D01B50;
}
</style>
