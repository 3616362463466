<template>
  <div class="home" id="home">
    <div class="content">
      <img src="@/assets/logo.png" alt="Logo Icon">
      <div class="form">
        <h3>LOGIN</h3>
        <hr />
        <form @submit.prevent="login">
          <label>Enter Your Email:</label>
          <input type="email" v-model="loginData.email" placeholder="Your Email" required/>
          <label>Enter Your Password:</label>
          <input type="password" v-model="loginData.password" placeholder="Password" required/>
          <input type="submit" class="background-pink" value="Login" :disabled="isLoading ? true:false" />
          <p class="error-message">{{ errorMessage }}</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HomeView",
  data() {
    return {
      loginData: {
        email: '',
        password: '',
      },
      errorMessage: '',
      isLoading: false
    }
  },
  methods: {
    login() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/admin/auth/login',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.loginData.email,
          password: this.loginData.password,
        })
      })
        .then(response => response.json())
        .then(data => {
          
          if (data.status && data.status == 200) {
            localStorage.setItem('admin_token', data.token);
            this.accessToken = localStorage.getItem('admin_token');
            this.$router.push({ name: "Industry"});
          }else{
            this.errorMessage = 'Invalid Information';
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  components: {},
};
</script>

<style>
.home{
  background-image: url("@/assets/images/base-background.png");
  background-size: cover;
  height: 100vh;
  color: black;
}
.home .content{
  width: 30%;
  margin: auto;
}
.home .content img{
  margin: 2rem 0;
  width: 12rem;
}
@media (max-width: 991px) {
  .home .content{
    width: 40%;
  }
}
@media (max-width: 767px) {
  .home .content{
    width: 50%;
  }
}
@media (max-width: 600px) {
  .home .content{
    width: 60%;
  }
}
@media (max-width: 500px) {
  .home .content{
    width: 70%;
  }
  .home .content img{
    width: 10rem;
  }
}
@media (max-width: 400px) {
  .home .content{
    width: 90%;
  }
}
/* Form */
.home .form{
  background-color: #FFF;
  box-shadow: 0 0 5px #ccc;
  border-radius: 2rem;
  padding: 3rem 2rem .5rem 2rem;
}
.home .form h3{
  font-weight: bold;
}
.home .form hr{
  background-color: #D01B50;
  opacity: 1;
  width: 6rem;
  border: none;
  height: 3px;
  margin: auto;
}
.home .form form{
  text-align: left;
}
.home .form form label{
  display: block;
  margin: 1rem 0 .5rem;
}
.home .form form input{
  background-color: #F1ECEC;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  width: 100%;
}
.home .form form input[type="submit"]{
  background-color: #D01B50;
  border: 2px solid #D01B50;
  box-shadow: 2px 2px 5px #CCC;
  color: #FFF;
  margin-top: 2rem;
  font-weight: bold;
}
.home .form form input[type="submit"]:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.home .form form input[type="submit"]:focus{
  background-color: #FFF;
  color: #D01B50;
}

</style>