<template>
  <div class="service" id="service">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#E63D36"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h3>Service Management</h3>
        <router-link to="/addService">+Add</router-link>
      </div>
      <div class="box">
        <perfect-scrollbar class="inner-box">
          <div class="data">

            <div v-for="service in services" :key="service.id" :data-id="service.id" class="card">
              <div class="head">
                <h5>{{ service.name }}</h5>
                <div class="icons">
                  <img class="edit-btn" src="@/assets/icons/edit.png" alt="Edit Icon">
                  <img class="delete-btn" src="@/assets/icons/delete.png"
                    data-bs-toggle="modal" data-bs-target="#deletModal" alt="Delete Icon">
                </div>
              </div>
              <form>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <label>Cover Image</label>
                    <label for="coverUpload" class="upload-icon">Image Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon"/>
                    </label>
                    <input type="file" name="cover_image" id="coverUpload" accept="image/*" style="display: none">
                  </div>
                  <div class="col-md-6">
                    <label>Name SVG</label>
                    <label for="svgUpload" class="upload-icon">SVG Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon"/>
                    </label>
                    <input type="file" name="name_icon" id="svgUpload" accept="image/*" style="display: none">
                  </div>
                  <div class="col-md-6">
                    <label>Icon</label>
                    <label for="iconUpload" class="upload-icon">Icon Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon"/>
                    </label>
                    <input type="file" name="icon" id="iconUpload" accept="image/*" style="display: none">
                  </div>
                  <div class="col-md-6">
                    <label>Image</label>
                    <label for="imageUpload" class="upload-icon">Image Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon"/>
                    </label>
                    <input type="file" name="image" id="imageUpload" accept="image/*" style="display: none">
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <label>Name</label>
                        <input type="text" name="en[name]" placeholder="Name" :value="service.name"/>
                      </div>
                      <div class="col-md-12">
                        <label>Subject</label>
                        <input type="text" name="en[subtitle]" placeholder="Text" :value="service.subtitle"/>
                      </div>
                      <div class="col-md-12">
                        <label>Short Description</label>
                        <textarea name="en[short_description]" v-model="service.short_description" placeholder="Description" rows="5"></textarea>
                      </div>
                      <div class="col-md-12">
                        <label>Full Description</label>
                        <textarea name="en[full_description]" v-model="service.full_description" placeholder="Description" rows="5"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row ar">
                      <div class="col-md-12">
                        <label>الاسم</label>
                        <input type="text" name="ar[name]" placeholder="الاسم" :value="service.ar.name"/>
                      </div>
                      <div class="col-md-12">
                        <label>عنوان فرعي</label>
                        <input type="text" name="ar[subtitle]" placeholder="النص" :value="service.ar.subtitle"/>
                      </div>
                      <div class="col-md-12">
                        <label>شرح مختصر</label>
                        <textarea name="ar[short_description]" v-model="service.ar.short_description" placeholder="شرح مختصر" rows="5"></textarea>
                      </div>
                      <div class="col-md-12">
                        <label>شرح كامل</label>
                        <textarea name="ar[full_description]" v-model="service.ar.full_description" placeholder="شرح كامل" rows="5"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="error-message" :id="'errors' + service.id">
                  <!--  -->
                </div>
                <input type="submit" class="submit-btn" value="Save" />
              </form>
            </div>

            <p v-if="noData" class="no-data">There Is No Data</p>

          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <app-delete-modal modalTitle="Delete Service"/>

  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import AppDeleteModal from '@/components/global/AppDeleteModal.vue'

export default {
  name: "ServiceView",
  data() {
    return {
      services: [],
      links: [],
      meta: {},
      deleteCardId: -1,
      noData: 0,
      isLoading: false
    }
  },
  methods: {
    getData() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/services',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.data.data != []){
            this.services = data.data.data;
            this.links = data.data.links;
            this.meta = data.data.meta;
            this.noData = 0;
          }else{
            this.noData = 1;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
    },
    updateService(id, data) {
      fetch(this.serverURL + 'api/services/' + id ,{
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: data,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 200){
            location.reload();
          }else{
            let messages = '';

            for(let propt in data.errors){
              messages += `<p>${data.errors[propt].join(', ')}</p>`;
            }
            window.$('#errors' + id).empty().append(messages);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteService(id) {
      fetch(this.serverURL + 'api/services/' + id,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          location.reload();
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  beforeMount() {
    this.getData();
  },
  mounted(){
    let that = this;

    // On Form Submit
    window.$('body').on('submit', '.service form', function(e){
      e.preventDefault();
      // window.$(this).slideUp(300);

      let id = window.$(this).parents('.card').data('id');
      // console.log(id);

      let inputs = window.$(this).find(':input[name]');
      let formData = new FormData();
      inputs.each(function() {
        if (this.type == 'file'){
          Array.from(this.files).forEach(el => {
            formData.append(this.name, el);
          });
        }
        else if (this.value != "")
          formData.append(this.name, this.value);
      });
      formData.append('_method', 'PUT');
      // console.log(Object.fromEntries(formData.entries()));

      that.updateService(id, formData);
    });

    // On Edit Button Click
    window.$('body').on('click', '.service .edit-btn', function(e){
      e.stopImmediatePropagation();
      window.$(this).parent().parent().next().slideDown(300);
    });

    // On Delete Button Click
    window.$('body').on('click', '.service .delete-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteCardId = window.$(this).parents('.card').data('id');
    });

    // On Yes Button Click
    window.$('body').on('click', '.service .yes-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteService(that.deleteCardId);
    });
  },
  components: {
    AppDeleteModal,
    Loading,
  },
};
</script>

<style>
.service{
  background-color: #1E1E1E;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.service .temp{
  min-width: 300px;
  transition: all .5s;
}
.close-rail .service .temp{
  min-width: 75px;
  transition: all .5s;
}
/* Content */
.service .content{
  position: relative;
  background-color: #F1F1F1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.service .content .title{
  min-height: 11%;
  display: flex;
  justify-content: space-between;
}
.service .content .title h3,
.service .content .title a{
  font-weight: bold;
}
.service .content .title a{
  text-decoration: none;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  box-shadow: 2px 2px 5px #CCC;
  color: #FFF;
  border-radius: 0.7rem;
  padding: .5rem 1.5rem;
  height: fit-content;
}
.service .content .title a:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.service .content .title a:focus{
  background-color: #FFF;
  color: #D01B50;
}
.service .content .box{
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 1px 5px #CCC;
  padding: 2rem 1.5rem;
  height: 90%;
}
.service .content .box .inner-box{
  height: 100%;
}
.service .content .box .inner-box .data{
  padding-right: 1.5rem;
}
.service .content .box .inner-box .ps__rail-y{
  border-radius: 1rem;
}
.service .content .box .inner-box .ps__thumb-y{
  background-color: #D01B50;
}
/* card */
.service .content .data .card{
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 1.3rem;
  color: black;
}
.service .content .data .card .head{
  display: flex;
  justify-content: space-between;
}
.service .content .data .card .head .icons img{
  width: 1.4rem;
  cursor: pointer;
}
.service .content .data .card .head .icons img:last-child{
  margin-left: 1rem;
}
/* Form */
.service .content .data .card form{
  display: none;
}
.service .content .data .card form hr{
  background-color: #DCDCDC;
  opacity: 1;
  border: none;
  height: 1px;
  margin-bottom: 0;
}
.service .content .data .card form .ar{
  direction: rtl;
  text-align: right;
}
.service .content .data .card form label{
  display: block;
  color: black;
  margin: 1rem 0 .5rem 0;
}
.service .content .data .card form input,
.service .content .data .card form label.upload-icon{
  padding: .5rem 1rem;
  border-radius: 2rem;
  width: 90%;
}
.service .content .data .card form input{
  border: 1px solid #CCC;
}
.service .content .data .card form label.upload-icon{
  background-color: #EFEFEF;
  color: #6B6B6B;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.service .content .data .card form label.upload-icon img{
  width: 1.2rem;
  height: 1.1rem;
}
.service .content .data .card form textarea{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 1rem;
  width: 100%;
}
.service .content .data .card form .submit-btn{
  padding: .5rem 1rem;
  border-radius: 1rem;
  color: #FFF;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  box-shadow: 2px 2px 5px #CCC;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 6rem;
}
.service .content .data .card form .submit-btn:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.service .content .data .card form .submit-btn:focus{
  background-color: #FFF;
  color: #D01B50;
}
</style>