<template>
  <div class="notification" id="notification">
    <div class="temp"></div>
    <div class="content">
      <div class="title">
        <h3>Notifications</h3>
        <router-link to="/sendNotification">+Add</router-link>
      </div>
      <div class="box">
        <perfect-scrollbar class="inner-box">
          <div class="data">

            <div class="card">
              <div class="head">
                <div class="row">
                  <div class="col-6">
                    <div class="heading">
                      <h5>Name</h5>
                      <h5>Subject</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="icons">
                      <img class="delete-btn" src="@/assets/icons/delete.png"
                      data-bs-toggle="modal" data-bs-target="#deletModal" alt="Delete Icon">  
                    </div>
                  </div>
                </div>
              </div>
              <form>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <label>Email</label>
                    <input type="email" name="email" value="Email" readonly/>
                  </div>
                  <div class="col-md-6 radio-group">
                    <div class="input-radio">
                      <input type="checkbox" name="all-users" disabled>
                      <label>Send To All Users</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label>Subject</label>
                    <input type="text" name="subject" value="Subject" readonly />
                  </div>
                  <div class="col-md-12">
                    <label>Message</label>
                    <textarea name="message" readonly>Message</textarea>
                  </div>
                </div>
              </form>
            </div>

            <div class="card">
              <div class="head">
                <div class="row">
                  <div class="col-6">
                    <div class="heading">
                      <h5>Name</h5>
                      <h5>Subject</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="icons">
                      <img class="delete-btn" src="@/assets/icons/delete.png"
                      data-bs-toggle="modal" data-bs-target="#deletModal" alt="Delete Icon">  
                    </div>
                  </div>
                </div>
              </div>
              <form>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <label>Email</label>
                    <input type="email" name="email" value="Email" readonly/>
                  </div>
                  <div class="col-md-6 radio-group">
                    <div class="input-radio">
                      <input type="checkbox" name="all-users" disabled>
                      <label>Send To All Users</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label>Subject</label>
                    <input type="text" name="subject" value="Subject" readonly />
                  </div>
                  <div class="col-md-12">
                    <label>Message</label>
                    <textarea name="message" readonly>Message</textarea>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </perfect-scrollbar>
      </div>
    </div>
  
    <app-delete-modal modalTitle="Delete Notification"/>
  </div>
</template>

<script>
import AppDeleteModal from '@/components/global/AppDeleteModal.vue'

export default {
  name: "NotificationView",
  props: [],
  methods: {},
  mounted(){
    // On Edit Button Click
    window.$('body').on('click', '.notification .card .heading', function(){
      window.$('.notification .card form').slideUp(300);
      window.$(this).parents('.head').next().slideDown(300);
    });

    // On Yes Button Click
    window.$('body').on('click', '.notification .yes-btn', function(){
      console.log("Delete Notification");
    });
  },
  components: {
    AppDeleteModal,
  },
};
</script>

<style>
.notification{
  background-color: #1E1E1E;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.notification .temp{
  min-width: 300px;
  transition: all .5s;
}
.close-rail .notification .temp{
  min-width: 75px;
  transition: all .5s;
}
/* Content */
.notification .content{
  position: relative;
  background-color: #F1F1F1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.notification .content .title{
  min-height: 11%;
  display: flex;
  justify-content: space-between;
}
.notification .content .title h3,
.notification .content .title a{
  font-weight: bold;
}
.notification .content .title a{
  text-decoration: none;
  background-color: #D01B50;
  box-shadow: 2px 2px 5px #CCC;
  color: #FFF;
  border-radius: 0.7rem;
  padding: .5rem 1.5rem;
  height: fit-content;
}
.notification .content .box{
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 1px 5px #CCC;
  padding: 2rem 1.5rem;
  height: 90%;
}
.notification .content .box .inner-box{
  height: 100%;
}
.notification .content .box .inner-box .data{
  padding-right: 1.5rem;
}
.notification .content .box .inner-box .ps__rail-y{
  border-radius: 1rem;
}
.notification .content .box .inner-box .ps__thumb-y{
  background-color: #D01B50;
}
/* card */
.notification .content .data .card{
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 1.3rem;
  color: black;
}
.notification .content .data .card .head .heading{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification .content .data .card .head h5:nth-child(2){
  border-left: 1px solid #DCDCDC;
  padding-left: .5rem;
}
.notification .content .data .card .head .icons{
  display: flex;
  justify-content: flex-end;
}
.notification .content .data .card .head .icons img{
  width: 1.4rem;
  cursor: pointer;
}
/* Form */
.notification .content .data .card form{
  display: none;
}
.notification .content .data .card form hr{
  background-color: #DCDCDC;
  opacity: 1;
  border: none;
  height: 1px;
  margin-bottom: 0;
}
.notification .content .data .card form label{
  display: block;
  margin: 1rem 0 .5rem 0;
}
.notification .content .data .card form input{
  padding: .5rem 1rem;
  border-radius: 2rem;
  width: 90%;
  border: 1px solid #CCC;
}
.notification .content .data .card form .radio-group{
  display: flex;
  align-items: end;
}
.notification .content .data .card form .input-radio{
  display: flex;
  justify-content: flex-start;
  color: #6B6B6B;
}
.notification .content .data .card form .input-radio input[type="checkbox"]{
  margin-right: .5rem;
  width:fit-content;
  accent-color: #d01b50;
}
.notification .content .data .card form .input-radio label{
  margin: 0;
}
.notification .content .data .card form input[name="subject"]{
  width: 100%;
}
.notification .content .data .card form textarea{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 1rem;
  width: 100%;
}
</style>