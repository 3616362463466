<template>
  <app-header v-if="!main" @toggle-rail="toggleRail"/>
  <router-view />
</template>

<script>
import AppHeader from '@/components/global/AppHeader.vue'

export default {
  name: 'App',
  data() {
    return {
      main: true,
    };
  },
  methods: {
    toggleRail(rail){
      if (rail){
        window.$('#app').addClass('close-rail');
      }else{
        window.$('#app').removeClass('close-rail');
      }
    },
    checkMainLocation(location){
      if (location != '/'){
        this.main = 0;
      } else{
        this.main = 1;
      }
    },
  },
  mounted () {
    // Get Current Location
    var location = window.location.pathname;

    this.checkMainLocation(location);

    // On URL Change
    setInterval(() => {
      const currUrl = window.location.pathname;
      if (currUrl != location) {
        location = currUrl;
        this.checkMainLocation(location);
      }
    }, 60);

  },
  components: {
    AppHeader,
  }
}
</script>

<style>
@font-face {
  font-family: Inter-Regular;
  src: url(@/assets/fonts/static/Inter-Regular.ttf);
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
html{
  overflow: hidden !important;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: Inter-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FFF;
}
.vl-overlay.vl-active{
  border-radius: 2rem;
}
.no-data{
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  color: #D01B50;
  margin-top: 20vh;
}
.error-message{
  margin-top: 1rem;
  text-align: center;
  font-weight: bold;
  color: red;
}
.error-message p{
  margin-bottom: 0;
}
</style>
