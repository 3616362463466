<template>
  <div class="add-plan" id="add-plan">
    <div class="temp"></div>
    <div class="content">
    <Loading v-model:active="isLoading"
                :can-cancel="true"
                :is-full-page="false"
                color="#E63D36"
                loader="bars"
                background-color="#000"
                :lock-scroll="false"
                />
      <div class="title">
        <h3>Add Plan</h3>
      </div>
      <div class="box">
        <perfect-scrollbar class="inner-box">
          <div class="data">
            <form @submit.prevent="addPlan">
              <div class="row">
                <div class="col-md-12">
                  <label>Service</label>
                  <select v-model="planData.service_id" required>
                    <option v-for="service in services" :key="service.id" :value="service.id">{{ service.name }}</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Pricing</label>
                  <input type="number" min="1" v-model="planData.price" placeholder="Pricing" required/>
                </div>
                <div class="col-md-6">
                  <label class="color-label">Color</label>
                  <div class="input-radio">
                    <input type="checkbox" v-model="planData.special">
                    <label>Colored</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <label>Name</label>
                      <input type="text" v-model="planData.en_name" placeholder="Name" required/>
                    </div>
                    <div class="col-md-12">
                      <label>Description</label>
                      <textarea v-model="planData.en_description"  placeholder="Description" required></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row ar">
                    <div class="col-md-12">
                      <label>الاسم</label>
                      <input type="text" v-model="planData.ar_name" placeholder="الاسم" required/>
                    </div>
                    <div class="col-md-12">
                      <label>الوصف</label>
                      <textarea v-model="planData.ar_description"  placeholder="الوصف" required></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="error-message">
                <p v-for="error in errorMessages" :key="error">{{ error }}</p>
              </div>
              <input type="submit" class="submit-btn" value="Submit" :disabled="isLoading ? true:false" />
            </form>
          </div>
        </perfect-scrollbar>
      </div>
    </div>  
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: "AddPlanView",
  data() {
    return {
      services: [],
      planData: {
        service_id: -1,
        price: '',
        special: false,
        en_name: '',
        ar_name: '',
        en_description: '',
        ar_description: '',
      },
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    getServices() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/services',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          this.services = data.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
    },
    addPlan() {
      this.isLoading = true;

      let formData = new FormData();
      formData.append("price", this.planData.price);
      if (this.planData.special)
        formData.append("special", 1);
      else
        formData.append("special", 0);
      formData.append("en[name]", this.planData.en_name);
      formData.append("ar[name]", this.planData.ar_name);
      formData.append("en[description]", this.planData.en_description);
      formData.append("ar[description]", this.planData.ar_description);

      fetch(this.serverURL + 'api/services/' + this.planData.service_id + '/plans',{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 201){
            this.$router.push({ name: "Plans"});
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(', '));
            }
            this.errorMessages = messages;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
    this.getServices();
  },
  mounted(){},
  components: {
    Loading,
  },
};
</script>

<style>
.add-plan{
  background-color: #1E1E1E;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.add-plan .temp{
  min-width: 300px;
  transition: all .5s;
}
.close-rail .add-plan .temp{
  min-width: 75px;
  transition: all .5s;
}
/* Content */
.add-plan .content{
  position: relative;
  background-color: #F1F1F1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.add-plan .content .title{
  min-height: 11%;
  display: flex;
  justify-content: space-between;
}
.add-plan .content .title h3{
  font-weight: bold;
}
.add-plan .content .box{
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 1px 5px #CCC;
  padding: 2rem 1.5rem;
  height: 90%;
}
.add-plan .content .box .inner-box{
  height: 100%;
}
.add-plan .content .box .inner-box .data{
  padding-right: 1.5rem;
}
.add-plan .content .box .inner-box .ps__rail-y{
  border-radius: 1rem;
}
.add-plan .content .box .inner-box .ps__thumb-y{
  background-color: #D01B50;
}
/* Form */
.add-plan .content .data form .ar{
  direction: rtl;
  text-align: right;
}
.add-plan .content .data form input,
.add-plan .content .data form select{
  padding: .5rem 1rem;
  border-radius: 2rem;
  width: 90%;
  border: 1px solid #CCC;
}
.add-plan .content .data form label{
  display: block;
  margin: 1rem 0 .5rem 0;
}
.add-plan .content .data form select {
  background-color: transparent;
  outline: none;
  cursor: pointer;
  width: 45%;
}
.add-plan .content .data form option{
  color: #000000;
}
.add-plan .content .data form label.color-label{
  margin-bottom: 0;
}
.add-plan .content .data form textarea{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 1rem;
  width: 100%;
}
.add-plan .content .data form .input-radio{
  display: flex;
  justify-content: flex-start;
  color: #6B6B6B;
}
.add-plan .content .data form .input-radio input[type="checkbox"]{
  margin-right: .5rem;
  width:fit-content;
  accent-color: #d01b50;
}
.add-plan .content .data form .submit-btn{
  padding: .5rem 1rem;
  border-radius: 1rem;
  color: #FFF;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 6rem;
}
.add-plan .content .data form .submit-btn:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.add-plan .content .data form .submit-btn:focus{
  background-color: #FFF;
  color: #D01B50;
}
</style>