<template>
  <div class="product" id="product">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#E63D36"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h3>Product Management</h3>
        <router-link to="/addProduct">+Add</router-link>
      </div>
      <div class="box">
        <div class="filter row">
          <perfect-scrollbar class="filter-box col-6">
            <div class="industry-filter">
              <img src="@/assets/icons/industry.png" alt="Industry Icon">
              <span>Industry</span>
              <div class="buttons">
                <button v-for="industry in industries" :key="industry.id" :data-id="industry.id">{{ industry.name }}</button>
              </div>
            </div>
          </perfect-scrollbar>
          <perfect-scrollbar class="filter-box col-6">
            <div class="service-filter">
              <img src="@/assets/icons/industry.png" alt="Service Icon">
              <span>Service</span>
              <div class="buttons">
                <button v-for="service in services" :key="service.id" :data-id="service.id">{{ service.name }}</button>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <perfect-scrollbar class="inner-box">
          <div class="data">

            <div v-for="product in products" :key="product.id" :data-id="product.id" class="card">
              <div class="head">
                <h5>{{ product.name }}</h5>
                <div class="icons">
                  <img class="edit-btn" src="@/assets/icons/edit.png" alt="Edit Icon" />
                  <img class="delete-btn" src="@/assets/icons/delete.png"
                    data-bs-toggle="modal" data-bs-target="#deletModal" alt="Delete Icon"> 
                </div>
              </div>
              <form>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <label>Service</label>
                    <select disabled>
                      <option value="1" selected>{{ product.service }}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label>Industry</label>
                    <select disabled>
                      <option value="1" selected>{{ product.industry }}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label>Product's View Image</label>
                    <label for="viewImage" class="upload-input"
                      >Image Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                    </label>
                    <input
                      type="file"
                      id="viewImage"
                      accept="image/*"
                      name="outside_image"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Product Image</label>
                    <label for="productImage" class="upload-input"
                      >Image Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                    </label>
                    <input
                      type="file"
                      id="productImage"
                      accept="image/*"
                      name="inside_image"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <label>Name</label>
                        <input type="text" name="en[name]" placeholder="Name" :value="product.name"/>
                      </div>
                      <div class="col-md-12">
                        <label>Short Description</label>
                        <textarea
                          name="en[short_description]"
                          v-model="product.short_description"
                          placeholder="Description"
                        ></textarea>
                      </div>
                      <div class="col-md-12">
                        <label>Full Description</label>
                        <textarea
                          name="en[full_description]"
                          v-model="product.full_description"
                          placeholder="Description"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row ar">
                      <div class="col-md-12">
                        <label>الاسم</label>
                        <input type="text" name="ar[name]" placeholder="الاسم" :value="product.ar.name"/>
                      </div>
                      <div class="col-md-12">
                        <label>الشرح المختصر</label>
                        <textarea
                          name="ar[short_description]"
                          v-model="product.ar.short_description"
                          placeholder="الوصف"
                        ></textarea>
                      </div>
                      <div class="col-md-12">
                        <label>الشرح الكامل</label>
                        <textarea
                          name="ar[full_description]"
                          v-model="product.ar.full_description"
                          placeholder="الوصف"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label>Mobile And PC</label>
                    <div class="radio-group">
                      <div class="input-radio">
                        <input type="radio" name="type" value="mobile" v-model="product.type">
                        <label>Mobile</label>
                      </div>
                      <div class="input-radio">
                        <input type="radio" name="type" value="pc" v-model="product.type">
                        <label>PC</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Mobile Images</label>
                    <label :for="'mobileImages' + product.id" class="upload-input"
                      >Image Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                    </label>
                    <input
                      type="file"
                      :id="'mobileImages' + product.id"
                      @change="handleMobileImages"
                      accept="image/*"
                      name="mobile_images[]"
                      style="display: none"
                      multiple
                      :disabled="product.type == 'pc'?true:false"
                    />
                    <div class="image-preview" :id="'mobile-preview' + product.id">
                      <!--  -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>PC Images</label>
                    <label :for="'pcImages' + product.id" class="upload-input"
                      >Image Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon" />
                    </label>
                    <input
                      type="file"
                      :id="'pcImages' + product.id"
                      @change="handlePCImages"
                      accept="image/*"
                      name="pc_images[]"
                      style="display: none"
                      multiple
                      :disabled="product.type == 'mobile'?true:false"
                    />
                    <div class="image-preview" :id="'pc-preview' + product.id">
                      <!--  -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Demo Link</label>
                    <input type="text" name="demo_link" placeholder="https://" :value="product.demo_link"/>
                  </div>
                  <div class="col-md-6">
                    <label>Price</label>
                    <input type="number" name="price" placeholder="Price" :value="product.price"/>
                  </div>
                </div>
                <div class="error-message" :id="'errors' + product.id">
                  <!--  -->
                </div>
                <input type="submit" class="submit-btn" value="Submit" :disabled="isLoading ? true:false" />
              </form>
            </div>

            <p v-if="noData" class="no-data">There Is No Data</p>

          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <app-delete-modal modalTitle="Delete Product"/>

  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import AppDeleteModal from '@/components/global/AppDeleteModal.vue'

export default {
  name: "ProductView",
  data() {
    return {
      filter: {
        service_id: [],
        industry_id: [],
      },
      products: [],
      services: [],
      industries: [],
      deleteCardId: -1,
      noData: 0,
      isLoading: false
    }
  },
  methods: {
    getServices() {
      fetch(this.serverURL + 'api/services', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          this.services = data.data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getIndustries() {
      fetch(this.serverURL + 'api/industries',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          this.industries = data.data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getProducts() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/products?' +
      '&filter[service_id]=' + this.filter.service_id.join(',') +
      '&filter[industry_id]=' + this.filter.industry_id.join(','),{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.data.data != []){
            this.products = data.data.data;
            this.noData = 0;
          }else{
            this.noData = 1;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
    },
    updateProduct(id, data) {
      fetch(this.serverURL + 'api/products/' + id ,{
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: data,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 200){
            location.reload();
          }else{
            let messages = '';

            for(let propt in data.errors){
              messages += `<p>${data.errors[propt].join(', ')}</p>`;
            }
            window.$('#errors' + id).empty().append(messages);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteProduct(id) {
      fetch(this.serverURL + 'api/products/' + id,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          location.reload();
        })
        .catch(error => {
          console.log(error);
        })
    },

    // View Functions
    handleMobileImages: function (e){
      let files = e.target.files;
      let product_id = window.$(e.target).parents('.card').data('id');
      this.updateMobilePreview(product_id, files)
    },
    handlePCImages: function (e){
      let files = e.target.files;
      let product_id = window.$(e.target).parents('.card').data('id');
      this.updatePCPreview(product_id, files)
    },
    updateMobilePreview: function (product_id, files) {
      let result = '';

      window.$.each(files, function (key, file){
        result += `
          <div class="image">
            <i class="mobile-delete-icon delete-icon mdi mdi-cancel" data-id="${key}"></i>
            <img src="${URL.createObjectURL(file)}" alt="Mobile Image">
          </div>
        `;
      });
      window.$("#mobile-preview" + product_id).empty().append(result);
    },
    updatePCPreview: function (product_id, files) {
      let result = '';

      window.$.each(files, function (key, file){
        result += `
          <div class="image">
            <i class="pc-delete-icon delete-icon mdi mdi-cancel" data-id="${key}"></i>
            <img src="${URL.createObjectURL(file)}" alt="PC Image">
          </div>
        `;
      });
      window.$("#pc-preview" + product_id).empty().append(result);
    },
  },
  beforeMount() {
    this.getServices();
    this.getIndustries();
    this.getProducts();
  },
  mounted() {
    let that = this;

    // On Form Submit
    window.$('body').on('submit', '.product form', function(e){
      e.stopImmediatePropagation();
      e.preventDefault();
      // window.$(this).slideUp(300);

      let id = window.$(this).parents('.card').data('id');
      // console.log(id);

      let inputs = window.$(this).find(':input[name]');
      let formData = new FormData();
      inputs.each(function() {
        if (this.type == 'radio'){
          if (this.checked)
            formData.append(this.name, this.value);
        }
        else if (this.type == 'file'){
          Array.from(this.files).forEach(el => {
            formData.append(this.name, el);
          });
        }
        else if (this.value != "")
          formData.append(this.name, this.value);
      });
      formData.append('_method', 'PUT');
      // console.log(Object.fromEntries(formData.entries()));

      that.updateProduct(id, formData);
    });

    // On Edit Button Click
    window.$('body').on('click', '.product .edit-btn', function(e){
      e.stopImmediatePropagation();
      window.$(this).parent().parent().next().slideDown(500);
    });

    // On Delete Button Click
    window.$('body').on('click', '.product .delete-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteCardId = window.$(this).parents('.card').data('id');
    });

    // On Yes Button Click
    window.$('body').on('click', '.product .yes-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteProduct(that.deleteCardId);
    });

    // On Industry Button Clicked
    window.$("body").on("click", ".industry-filter .buttons button", function (e) {
      e.stopImmediatePropagation();

      window.$(this).toggleClass("active");
      let id = window.$(this).data('id');
      let index = that.filter.industry_id.indexOf(id);

      if (index === -1) {
        that.filter.industry_id.push(id);
      } else {
        that.filter.industry_id.splice(index, 1);
      }
      that.getProducts();
    });

    // On Service Button Clicked
    window.$("body").on("click", ".service-filter .buttons button", function (e) {
      e.stopImmediatePropagation();

      window.$(this).toggleClass("active");
      let id = window.$(this).data('id');
      let index = that.filter.service_id.indexOf(id);

      if (index === -1) {
        that.filter.service_id.push(id);
      } else {
        that.filter.service_id.splice(index, 1);
      }
      that.getProducts();
    });

    // On Radio Input Clicked
    window.$('body').on('click', '.product .input-radio input[type="radio"]', function(e){
      e.stopImmediatePropagation();

      let product_id = window.$(e.target).parents('.card').data('id');

      let value = this.value;
      if (value == 'mobile'){
        document.getElementById("mobileImages" + product_id).disabled = false;
        document.getElementById("pcImages" + product_id).disabled = true;
      }else{
        document.getElementById("pcImages" + product_id).disabled = false;
        document.getElementById("mobileImages" + product_id).disabled = true;
      }
    });

    // On Delete Mobile Image
    window.$('body').on('click', '.product .mobile-delete-icon', function(e){
      e.stopImmediatePropagation();

      let product_id = window.$(e.target).parents('.card').data('id');

      let dt = new DataTransfer();
      let id = window.$(this).data('id');
      let input = document.getElementById('mobileImages' + product_id);
      
      window.$.each(input.files, function (key, file){
        if (id !== key)
          dt.items.add(file);
      });
      input.files = dt.files;

      that.updateMobilePreview(product_id, input.files);
    });

    // On Delete PC Image
    window.$('body').on('click', '.product .pc-delete-icon', function(e){
      e.stopImmediatePropagation();

      let product_id = window.$(e.target).parents('.card').data('id');

      let dt = new DataTransfer();
      let id = window.$(this).data('id');
      let input = document.getElementById('pcImages' + product_id);

      window.$.each(input.files, function (key, file){
        if (id !== key)
          dt.items.add(file);
      });
      input.files = dt.files;

      console.log(input.files);

      that.updatePCPreview(product_id, input.files);
    });
  },
  components: {
    AppDeleteModal,
    Loading,
  },
};
</script>

<style>
.product {
  background-color: #1e1e1e;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.product .temp {
  min-width: 300px;
  transition: all .5s;
}
.close-rail .product .temp {
  min-width: 75px;
  transition: all 0.5s;
}
/* Content */
.product .content {
  position: relative;
  background-color: #f1f1f1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.product .content .title {
  min-height: 11%;
  display: flex;
  justify-content: space-between;
}
.product .content .title h3,
.product .content .title a {
  font-weight: bold;
}
.product .content .title a {
  text-decoration: none;
  background-color: #d01b50;
  border: 2px solid #D01B50;
  box-shadow: 2px 2px 5px #ccc;
  color: #fff;
  border-radius: 0.7rem;
  padding: 0.5rem 1.5rem;
  height: fit-content;
}
.product .content .title a:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.product .content .title a:focus{
  background-color: #FFF;
  color: #D01B50;
}
.product .content .box {
  overflow: hidden;
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 5px #ccc;
  padding: 2rem 1.5rem;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
/* Filter */
.product .content .box .filter {
  max-height: 25%;
  text-align: left;
}
.product .content .box .filter .filter-box{
  height: 100%;
}
.product .content .box .service-filter,
.product .content .box .industry-filter {
  padding-right: 1rem;
}
.product .content .box .filter img{
  width: 1.8rem;
  margin-right: 1rem;
}
.product .content .box .filter span{
  margin-right: 2rem;
}
.product .content .box .filter .buttons{
  margin-top: .5rem;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
}
.product .content .box .filter .buttons::-webkit-scrollbar{
  display: none;
}
.product .content .box .filter .buttons button{
  margin-right: 1rem;
  margin-top: .5rem;
  height: max-content;
  padding: .5rem;
  border-radius: .5rem;
  border: 1px solid #3C3C3C;
  background-color: transparent;
  transition: all .5s;
}
.product .content .box .filter .buttons button:hover{
  background-color: #E8D8DD;
}
.product .content .box .filter .buttons button.active{
  background-color: #D01B50;
  color: #FFF;
  border: none;
}
.product .content .box .inner-box {
  margin-top: 1rem;
}
.product .content .box .inner-box .data {
  padding-right: 1.5rem;
}
.product .content .box .inner-box .ps__rail-y {
  border-radius: 1rem;
}
.product .content .box .inner-box .ps__thumb-y {
  background-color: #d01b50;
}
/* card */
.product .content .data .card {
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 1.3rem;
  color: black;
}
.product .content .data .card .head {
  display: flex;
  justify-content: space-between;
}
.product .content .data .card .head .icons img {
  width: 1.4rem;
  cursor: pointer;
}
.product .content .data .card .head .icons img:last-child {
  margin-left: 1rem;
}
/* Form */
.product .content .data .card form {
  display: none;
}
.product .content .data .card form hr {
  background-color: #dcdcdc;
  opacity: 1;
  border: none;
  height: 1px;
  margin-bottom: 0;
}
.product .content .data form select {
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.product .content .data .card form label {
  display: block;
  margin: 1rem 0 .5rem 0;
}
.product .content .data .card form input,
.product .content .data .card form label.upload-input,
.product .content .data form select {
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  width: 90%;
}
.product .content .data .card form label.upload-input {
  border: none;
  background-color: #efefef;
  color: #6b6b6b;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.product .content .data .card form label.upload-input img {
  width: 1.2rem;
  height: 1.1rem;
}
.product .content .data .card form textarea {
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  width: 100%;
}
.product .content .data .card form .radio-group{
  display: flex;
  justify-content: flex-start;
}
.product .content .data .card form .radio-group .input-radio{
  display: flex;
  justify-content: flex-start;
  color: #6B6B6B;
}
.product .content .data .card form .radio-group .input-radio:not(:last-child){
  margin-right: 1rem;
}
.product .content .data .card form .radio-group .input-radio input[type="radio"]{
  margin-right: .5rem;
  accent-color: #d01b50;
}
.product .content .data form .image-preview{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 1rem;
}
.product .content .data form .image-preview .image{
  position: relative;
  width: 5rem;
  margin-bottom: .3rem;
}
.product .content .data form .image-preview .image img{
  width: 100%;
  height: 100%;
}
.product .content .data form .image-preview .image .delete-icon{
  position: absolute;
  top: -5px;
  right: 0;
  cursor: pointer;
}
.product .content .data .card form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  color: #fff;
  background-color: #d01b50;
  border: 2px solid #D01B50;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 6rem;
}
.product .content .data .card form .submit-btn:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.product .content .data .card form .submit-btn:focus{
  background-color: #FFF;
  color: #D01B50;
}
</style>
