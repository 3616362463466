<template>
  <div class="options" id="options">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#E63D36"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h3>Options Management</h3>
        <router-link to="/addOption">+Add</router-link>
      </div>
      <div class="box">
        <perfect-scrollbar class="filter-box">
          <div class="services-buttons">
            <button v-for="service,index in services" :key="service.id" :data-id="service.id"
                    :class="index == 0?'btn btn-default filter-button active':'btn btn-default filter-button'"
                    :data-filter="service.name.replace(/[^a-zA-Z0-9]/g, '')">
              {{ service.name }}
            </button>
          </div>
        </perfect-scrollbar>
        <perfect-scrollbar class="inner-box">
          <div class="data">
            <div  v-for="option in options" :key="option.id"
              :class="'card filter ' + option.service.name.replace(/[^a-zA-Z0-9]/g, '')"
              :style="option.service.name != first_service_name?'display: none;':''"
              :data-id="option.id"
              :data-service="option.service.id"
              >
              <div class="head">
                <h5>{{ option.name }}</h5>
                <div class="icons">
                  <img class="edit-btn" src="@/assets/icons/edit.png" alt="Edit Icon">
                  <img class="delete-btn" src="@/assets/icons/delete.png"
                    data-bs-toggle="modal" data-bs-target="#deletModal" alt="Delete Icon">
                </div>
              </div>
              <form>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <label>Service</label>
                    <select disabled>
                      <option :value="option.service.id" selected>{{ option.service.name }}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label>Icon</label>
                    <label for="iconUpload" class="upload-icon">Icon Upload
                      <img src="@/assets/icons/upload.png" alt="Upload Icon"/>
                    </label>
                    <input type="file" name="icon" id="iconUpload" accept="image/*" style="display: none"/>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <label>Name</label>
                        <input type="text" name="en[name]" placeholder="Name" :value="option.name"/>
                      </div>
                      <div class="col-md-12">
                        <label>Description</label>
                        <textarea name="en[description]" placeholder="Description" v-model="option.description" rows="5"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row ar">
                      <div class="col-md-12">
                        <label>الاسم</label>
                        <input type="text" name="ar[name]" placeholder="الاسم" :value="option.ar.name"/>
                      </div>
                      <div class="col-md-12">
                        <label>الوصف</label>
                        <textarea name="ar[description]" placeholder="الوصف" v-model="option.ar.description" rows="5"></textarea>
                      </div>
                    </div>
                  </div>
                </div>  
                <div class="error-message" :id="'errors' + option.id">
                  <!--  -->
                </div>            
                <input type="submit" class="submit-btn" value="Submit" />
              </form>
            </div>

            <p v-if="noData" class="no-data">There Is No Data</p>

          </div>
        </perfect-scrollbar>
      </div>
    </div>

    <app-delete-modal modalTitle="Delete Option"/>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import AppDeleteModal from '@/components/global/AppDeleteModal.vue'

export default {
  name: "OptionsView",
  data() {
    return {
      services: [],
      options: [],
      first_service_name: '',
      deleteCardId: -1,
      deleteCardService: -1,
      noData: 0,
      isLoading: true,
    }
  },
  methods: {
    getServices() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/services',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.data.data != []){
            this.services = data.data.data;
            this.services.forEach(service => {
              this.getOptions(service);
            });
            this.noData = 0;
          }else{
            this.noData = 1;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getOptions(service) {
      fetch(this.serverURL + 'api/services/' + service.id + '/options', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          let array = data.data;
          array.forEach(el => {
            el["service"] = service;
            this.options.push(el);
          });
        })
        .catch(error => {
          console.log(error);
        })
    },
    updateOption(service_id, option_id, data) {
      fetch(this.serverURL + 'api/services/' + service_id + '/options/' + option_id,{
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: data,
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (data.status == 200){
            location.reload();
          }else{
            let messages = '';

            for(let propt in data.errors){
              messages += `<p>${data.errors[propt].join(', ')}</p>`;
            }
            window.$('#errors' + option_id).empty().append(messages);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteOption(service_id, option_id) {
      fetch(this.serverURL + 'api/services/' + service_id + '/options/' + option_id,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          location.reload();
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  beforeMount() {
    this.getServices();
  },
  mounted(){
    let that = this;

    // On Form Submit
    window.$('body').on('submit', '.options form', function(e){
      e.stopImmediatePropagation();
      e.preventDefault();
      // window.$(this).slideUp(300);

      let option_id = window.$(this).parents('.card').data('id');
      let service_id = window.$(this).parents('.card').data('service');
      // console.log(service_id, option_id);

      let inputs = window.$(this).find(':input[name]');
      let formData = new FormData();
      inputs.each(function() {
        if (this.type == 'file'){
          Array.from(this.files).forEach(el => {
            formData.append(this.name, el);
          });
        }
        else if (this.value != "")
          formData.append(this.name, this.value);
      });
      formData.append('_method', 'PUT');
      // console.log(Object.fromEntries(formData.entries()));

      that.updateOption(service_id, option_id, formData);
    });

    // On Edit Button Click
    window.$('body').on('click', '.options .edit-btn', function(e){
      e.stopImmediatePropagation();
      window.$(this).parent().parent().next().slideDown(300);
    });

    // On Delete Button Click
    window.$('body').on('click', '.options .delete-btn', function(e){
      e.stopImmediatePropagation();      
      that.deleteCardId = window.$(this).parents('.card').data('id');
      that.deleteCardService = window.$(this).parents('.card').data('service');
    });

    // On Yes Button Click
    window.$('body').on('click', '.options .yes-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteOption(that.deleteCardService, that.deleteCardId);
    });

    // On Services Buttons Clicked
    window.$("body").on('click', '.options .filter-button', function (e) {
      e.stopImmediatePropagation();
      let value = window.$(this).data('filter');

      window
          .$(".options .filter")
          .not("." + value)
          .hide("3000");
      window
          .$(".options .filter")
          .filter("." + value)
          .show("3000");

      window.$(".options .filter-button").removeClass("active");
      window.$(this).addClass("active");
    });
  },
  watch: {
    services: function() {
      this.first_service_name = this.services[0].name;
    }
  },
  components: {
    AppDeleteModal,
    Loading,
  },
};
</script>

<style>
.options{
  background-color: #1E1E1E;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.options .temp{
  min-width: 300px;
  transition: all .5s;
}
.close-rail .options .temp{
  min-width: 75px;
  transition: all .5s;
}
/* Content */
.options .content{
  position: relative;
  background-color: #F1F1F1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.options .content .title{
  min-height: 11%;
  display: flex;
  justify-content: space-between;
}
.options .content .title h3,
.options .content .title a{
  font-weight: bold;
}
.options .content .title a{
  text-decoration: none;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  box-shadow: 2px 2px 5px #CCC;
  color: #FFF;
  border-radius: 0.7rem;
  padding: .5rem 1.5rem;
  height: fit-content;
}
.options .content .title a:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.options .content .title a:focus{
  background-color: #FFF;
  color: #D01B50;
}
.options .content .box{
  overflow: hidden;
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 1px 5px #CCC;
  padding: 2rem 1.5rem;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.options .content .box .filter-box {
  max-height: 25%;
}
.options .content .box .inner-box {
  margin-top: 1.5rem;
}
.options .content .box .inner-box .data{
  padding-right: 1.5rem;
}
.options .content .box .inner-box .ps__rail-y{
  border-radius: 1rem;
}
.options .content .box .inner-box .ps__thumb-y{
  background-color: #D01B50;
}
/* Buttons */
.options .content .services-buttons {
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-x: auto;
}
.options .content .services-buttons::-webkit-scrollbar{
  display: none;
}
.options .content .filter-button {
  background-color: #EAEAEA;
  text-align: center;
  border-radius: 0;
  color: #3C3C3C;
  border: none;
}

.options .content .filter-button:hover,
.options .content .filter-button.active {
  color: #FFF;
  background-color: #4C0002;
}

.options .content .filter-button:not(:last-of-type) {
  border-right: 1px solid #4C000250;
}
/* card */
.options .content .data .card{
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 1.3rem;
  color: black;
}
.options .content .data .card .head{
  display: flex;
  justify-content: space-between;
}
.options .content .data .card .head .icons img{
  width: 1.4rem;
  cursor: pointer;
}
.options .content .data .card .head .icons img:last-child{
  margin-left: 1rem;
}
/* Form */
.options .content .data .card form{
  display: none;
}
.options .content .data .card form hr{
  background-color: #DCDCDC;
  opacity: 1;
  border: none;
  height: 1px;
  margin-bottom: 0;
}
.options .content .data .card form .ar{
  direction: rtl;
  text-align: right;
}
.options .content .data .card form select {
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.options .content .data .card form option{
  color: #000000;
}
.options .content .data .card form label:not(.upload-icon){
  display: block;
  color: black;
  margin: 1rem 0 .5rem 0;
}
.options .content .data .card form input,
.options .content .data .card form label.upload-icon,
.options .content .data .card form select{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 2rem;
  width: 90%;
}
.options .content .data .card form label.upload-icon{
  background-color: #EFEFEF;
  color: #6B6B6B;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: none;
}
.options .content .data .card form label.upload-icon img{
  width: 1.2rem;
  height: 1.1rem;
}
.options .content .data .card form textarea{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 1rem;
  width: 100%;
}
.options .content .data .card form .submit-btn{
  padding: .5rem 1rem;
  border-radius: 1rem;
  color: #FFF;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 6rem;
}
.options .content .data .card form .submit-btn:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.options .content .data .card form .submit-btn:focus{
  background-color: #FFF;
  color: #D01B50;
}
</style>