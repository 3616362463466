<template>
  <div class="add-feature" id="add-feature">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#E63D36"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h3>Add Feature</h3>
      </div>
      <div class="box">
        <perfect-scrollbar class="inner-box">
          <div class="data">
            <form @submit.prevent="addFeature">
              <div class="row">
                <div class="col-md-12">
                  <label>Product</label>
                  <select v-model="featureData.product_id" required>
                    <option v-for="product in products" :key="product.id" :value="product.id">{{ product.name }}</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Icon</label>
                  <label for="iconUpload" class="upload-icon">Icon Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon"/>
                  </label>
                  <input type="file" @change="handleIconChange" id="iconUpload" accept="image/*" style="display: none"/>
                </div>
                <div class="col-md-6">
                  <label>Image</label>
                  <label for="imageUpload" class="upload-icon">Image Upload
                    <img src="@/assets/icons/upload.png" alt="Upload Icon"/>
                  </label>
                  <input type="file" @change="handleImageChange" id="imageUpload" accept="image/*" style="display: none"/>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <label>Name</label>
                      <input type="text" v-model="featureData.en_name" placeholder="Name" required/>
                    </div>
                    <div class="col-md-12">
                      <label>Description</label>
                      <textarea v-model="featureData.en_description" placeholder="Description" rows="5" required></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row ar">
                    <div class="col-md-12">
                      <label>الاسم</label>
                      <input type="text" v-model="featureData.ar_name" placeholder="الاسم" required/>
                    </div>
                    <div class="col-md-12">
                      <label>الوصف</label>
                      <textarea v-model="featureData.ar_description" placeholder="الوصف" rows="5" required></textarea>
                    </div>
                  </div>
                </div>
              </div>  
              <div class="error-message">
                <p v-for="error in errorMessages" :key="error">{{ error }}</p>
              </div>            
              <input type="submit" class="submit-btn" value="Submit" :disabled="isLoading ? true:false" />
            </form>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: "AddFeatureView",
  data() {
    return {
      products: [],
      featureData: {
        product_id: -1,
        icon: null,
        image: null,
        en_name: '',
        ar_name: '',
        en_description: '',
        ar_description: '',
      },
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    handleIconChange(e) {
      const selectedFile = e.target.files[0];
      this.featureData.icon = selectedFile;
    },
    handleImageChange(e) {
      const selectedFile = e.target.files[0];
      this.featureData.image = selectedFile;
    },
    getProducts() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/products',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          this.products = data.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
    },
    addFeature() {
      this.isLoading = true;

      let formData = new FormData();
      formData.append("icon", this.featureData.icon);
      formData.append("image", this.featureData.image);
      formData.append("en[name]", this.featureData.en_name);
      formData.append("ar[name]", this.featureData.ar_name);
      formData.append("en[description]", this.featureData.en_description);
      formData.append("ar[description]", this.featureData.ar_description);

      fetch(this.serverURL + 'api/products/' + this.featureData.product_id + '/features',{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 201){
            this.$router.push({ name: "Features"});
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(', '));
            }
            this.errorMessages = messages;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
    this.getProducts();
  },
  components: {
    Loading,
  },
};
</script>

<style>
.add-feature{
  background-color: #1E1E1E;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.add-feature .temp{
  min-width: 300px;
  transition: all .5s;
}
.close-rail .add-feature .temp{
  min-width: 75px;
  transition: all .5s;
}
/* Content */
.add-feature .content{
  position: relative;
  background-color: #F1F1F1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.add-feature .content .title{
  min-height: 11%;
}
.add-feature .content .title h3{
  font-weight: bold;
}
.add-feature .content .box{
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 1px 5px #CCC;
  padding: 2rem 1.5rem;
  height: 90%;
}
.add-feature .content .box .inner-box{
  height: 100%;
}
.add-feature .content .box .inner-box .data{
  padding-right: 1.5rem;
}
.add-feature .content .box .inner-box .ps__rail-y{
  border-radius: 1rem;
}
.add-feature .content .box .inner-box .ps__thumb-y{
  background-color: #D01B50;
}
/* Form */
.add-feature .content .data form .ar{
  direction: rtl;
  text-align: right;
}
.add-feature .content .data form label:not(.upload-icon){
  display: block;
  color: black;
  margin: 1rem 0 .5rem 0;
}
.add-feature .content .data form input,
.add-feature .content .data form label.upload-icon,
.add-feature .content .data form select{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 2rem;
  width: 90%;
}
.add-feature .content .data form select {
  background-color: transparent;
  outline: none;
  cursor: pointer;
  width: 40%;
}
.add-feature .content .data form option{
  color: #000000;
}
.add-feature .content .data form label.upload-icon{
  background-color: #EFEFEF;
  color: #6B6B6B;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: none;
}
.add-feature .content .data form label.upload-icon img{
  width: 1.2rem;
  height: 1.1rem;
}
.add-feature .content .data form textarea{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 1rem;
  width: 100%;
}
.add-feature .content .data form .submit-btn{
  padding: .5rem 1rem;
  border-radius: 1rem;
  color: #FFF;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 6rem;
}
.add-feature .content .data form .submit-btn:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.add-feature .content .data form .submit-btn:focus{
  background-color: #FFF;
  color: #D01B50;
}
</style>