<template>
  <div class="message" id="message">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#E63D36"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h3>Message Management</h3>
      </div>
      <div class="box">
        <perfect-scrollbar class="inner-box">
          <div class="data">

            <div v-for="message in messages" :key="message.id" :data-id="message.id"
             class="card">
              <div class="head">
                <div class="row">
                  <div class="col-6">
                    <div class="heading">
                      <h5>{{ message.sender_name }}</h5>
                      <h5>{{ message.subject }}</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="icons">
                      <img class="delete-btn" src="@/assets/icons/delete.png"
                      data-bs-toggle="modal" data-bs-target="#deletModal" alt="Delete Icon"> 
                    </div>
                  </div>
                </div>
              </div>
              <form>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <label>Name</label>
                    <input type="text" :value="message.sender_name" readonly />
                  </div>
                  <div class="col-md-6">
                    <label>Email</label>
                    <input type="email" :value="message.sender_email" readonly />
                  </div>
                  <div class="col-md-12">
                    <label>Subject</label>
                    <input type="text" :value="message.subject" readonly />
                  </div>
                  <div class="col-md-12">
                    <label>Message</label>
                    <textarea v-model="message.content" readonly>Message</textarea>
                  </div>
                </div>
              </form>
            </div>

            <p v-if="noData" class="no-data">There Is No Data</p>

          </div>
        </perfect-scrollbar>
      </div>
    </div>
  
    <app-delete-modal modalTitle="Delete Message"/>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import AppDeleteModal from '@/components/global/AppDeleteModal.vue'

export default {
  name: "MessageView",
  data() {
    return {
      messages: [],
      deleteCardId: -1,
      noData: 0,
      isLoading: false
    }
  },
  methods: {
    getData() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/messages',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.data.data != []){
            this.messages = data.data.data;
            this.noData = 0;
          }else{
            this.noData = 1;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteMessage(id) {
      fetch(this.serverURL + 'api/messages/' + id,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          location.reload();
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  beforeMount() {
    this.getData();
  },
  mounted(){
    let that = this;

    // On Edit Button Click
    window.$('body').on('click', '.message .card .heading', function(e){
      e.stopImmediatePropagation();

      window.$('.message .card form').slideUp(300);
      window.$(this).parents('.head').next().slideDown(300);
    });

    // On Delete Button Click
    window.$('body').on('click', '.message .delete-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteCardId = window.$(this).parents('.card').data('id');
    });

    // On Yes Button Click
    window.$('body').on('click', '.message .yes-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteMessage(that.deleteCardId);
    });
  },
  components: {
    AppDeleteModal,
    Loading,
  },
};
</script>

<style>
.message{
  background-color: #1E1E1E;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.message .temp{
  min-width: 300px;
  transition: all .5s;
}
.close-rail .message .temp{
  min-width: 75px;
  transition: all .5s;
}
/* Content */
.message .content{
  position: relative;
  background-color: #F1F1F1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.message .content .title{
  min-height: 11%;
  display: flex;
  justify-content: space-between;
}
.message .content .title h3{
  font-weight: bold;
}
.message .content .box{
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 1px 5px #CCC;
  padding: 2rem 1.5rem;
  height: 90%;
}
.message .content .box .inner-box{
  height: 100%;
}
.message .content .box .inner-box .data{
  padding-right: 1.5rem;
}
.message .content .box .inner-box .ps__rail-y{
  border-radius: 1rem;
}
.message .content .box .inner-box .ps__thumb-y{
  background-color: #D01B50;
}
/* card */
.message .content .data .card{
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 1.3rem;
  color: black;
}
.message .content .data .card .head .heading{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message .content .data .card .head h5:nth-child(2){
  border-left: 1px solid #DCDCDC;
  padding-left: .5rem;
}
.message .content .data .card .head .icons{
  display: flex;
  justify-content: flex-end;
}
.message .content .data .card .head .icons img{
  width: 1.4rem;
  cursor: pointer;
}
/* Form */
.message .content .data .card form{
  display: none;
}
.message .content .data .card form hr{
  background-color: #DCDCDC;
  opacity: 1;
  border: none;
  height: 1px;
  margin-bottom: 0;
}
.message .content .data .card form label{
  display: block;
  margin: 1rem 0 .5rem 0;
}
.message .content .data .card form input{
  padding: .5rem 1rem;
  border-radius: 2rem;
  width: 90%;
  border: 1px solid #CCC;
}
.message .content .data .card form input[name="subject"]{
  width: 100%;
}
.message .content .data .card form textarea{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 1rem;
  width: 100%;
}
</style>