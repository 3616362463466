<template>
  <div class="industry" id="industry">
    <div class="temp"></div>
    <div class="content">
      <div class="title">
        <h3>Industry Management</h3>
        <router-link to="/addIndustry">+Add</router-link>
      </div>
      <div class="box">
        <perfect-scrollbar class="inner-box">
          <div class="data">

            <div v-for="indusrty in industries" :key="indusrty.id" :data-id="indusrty.id" class="card">
              <div class="head">
                <h5>{{ indusrty.name }}</h5>
                <div class="icons">
                  <img class="edit-btn" src="@/assets/icons/edit.png" alt="Edit Icon">
                  <img class="delete-btn" src="@/assets/icons/delete.png"
                    data-bs-toggle="modal" data-bs-target="#deletModal" alt="Delete Icon">
                </div>
              </div>
              <form>
                <hr />
                <div class="form-data">
                  <div class="en">
                    <label>Name</label>
                    <input type="text" name="en[name]" :value="indusrty.name" placeholder="Name"/>
                  </div>
                  <div class="ar">
                    <label>الاسم</label>
                    <input type="text" name="ar[name]" :value="indusrty.ar.name" placeholder="الاسم"/>
                  </div>
                </div>
                <div class="error-message" :id="'errors' + indusrty.id">
                  <!--  -->
                </div> 
                <input type="submit" class="submit-btn" value="Save" />
              </form>
            </div>

            <p v-if="noData" class="no-data">There Is No Data</p>

          </div>
        </perfect-scrollbar>
      </div>
    </div>

    <app-delete-modal modalTitle="Delete Industry"/>
  </div>
</template>

<script>
import AppDeleteModal from '@/components/global/AppDeleteModal.vue'

export default {
  name: "IndustryView",
  data() {
    return {
      industries: [],
      deleteCardId: -1,
      noData: 0,
      isLoading: false
    }
  },
  methods: {
    getData() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/industries',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.data.data != []){
            this.industries = data.data.data;
            this.noData = 0;
          }else{
            this.noData = 1;
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
    },
    updateIndustry(id, data) {
      fetch(this.serverURL + 'api/industries/' + id ,{
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: data,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status == 200){
            location.reload();
          }else{
            let messages = '';

            for(let propt in data.errors){
              messages += `<p>${data.errors[propt].join(', ')}</p>`;
            }
            window.$('#errors' + id).empty().append(messages);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteIndustry(id) {
      fetch(this.serverURL + 'api/industries/' + id,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          location.reload();
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  beforeMount() {
    this.getData();
  },
  mounted(){
    let that = this;

    // On Form Submit
    window.$('body').on('submit', '.industry form', function(e){
      e.stopImmediatePropagation();
      e.preventDefault();
      // window.$(this).slideUp(300);

      let id = window.$(this).parents('.card').data('id');
      // console.log(id);

      let inputs = window.$(this).find(':input[name]');
      let formData = new FormData();
      inputs.each(function() {
          if (this.value != "")
            formData.append(this.name, window.$(this).val());
      });
      formData.append('_method', 'PUT');
      // console.log(Object.fromEntries(formData.entries()));

      that.updateIndustry(id, formData);
    });

    // On Edit Button Click
    window.$('body').on('click', '.industry .edit-btn', function(e){
      e.stopImmediatePropagation();
      window.$(this).parent().parent().next().slideDown(300);
    });

    // On Delete Button Click
    window.$('body').on('click', '.industry .delete-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteCardId = window.$(this).parents('.card').data('id');
    });

    // On Yes Button Click
    window.$('body').on('click', '.industry .yes-btn', function(e){
      e.stopImmediatePropagation();
      that.deleteIndustry(that.deleteCardId);
    });
  },
  components: {
    AppDeleteModal,
  },
};
</script>

<style>
.industry{
  background-color: #1E1E1E;
  color: black;
  height: 100vh;
  padding: 1rem 2rem 1rem 0;
  display: flex;
}
.industry .temp{
  min-width: 300px;
  transition: all .5s;
}
.close-rail .industry .temp{
  min-width: 75px;
  transition: all .5s;
}
/* Content */
.industry .content{
  position: relative;
  background-color: #F1F1F1;
  height: 100%;
  width: -webkit-fill-available;
  text-align: left;
  border-radius: 2rem;
  padding: 2rem;
}
.industry .content .title{
  min-height: 11%;
  display: flex;
  justify-content: space-between;
}
.industry .content .title h3,
.industry .content .title a{
  font-weight: bold;
}
.industry .content .title a{
  text-decoration: none;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  box-shadow: 2px 2px 5px #CCC;
  color: #FFF;
  border-radius: 0.7rem;
  padding: .5rem 1.5rem;
  height: fit-content;
}
.industry .content .title a:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.industry .content .title a:focus{
  background-color: #FFF;
  color: #D01B50;
}
.industry .content .box{
  border-radius: 2rem;
  clear: both;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 1px 5px #CCC;
  padding: 2rem 1.5rem;
  height: 90%;
}
.industry .content .box .inner-box{
  height: 100%;
}
.industry .content .box .inner-box .data{
  padding-right: 1.5rem;
}
.industry .content .box .inner-box .ps__rail-y{
  border-radius: 1rem;
}
.industry .content .box .inner-box .ps__thumb-y{
  background-color: #D01B50;
}
/* card */
.industry .content .data .card{
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 1.3rem;
  color: black;
}
.industry .content .data .card .head{
  display: flex;
  justify-content: space-between;
}
.industry .content .data .card .head .icons img{
  width: 1.4rem;
  cursor: pointer;
}
.industry .content .data .card .head .icons img:last-child{
  margin-left: 1rem;
}
/* Form */
.industry .content .data .card form{
  display: none;
}
.industry .content .data .card form hr{
  background-color: #DCDCDC;
  opacity: 1;
  border: none;
  height: 1px;
  margin-bottom: 0;
}
.industry .content .data .card form .form-data{
  display: flex;
}
.industry .content .data .card form .form-data .ar{
  direction: rtl;
  text-align: right;
}
.industry .content .data .card form label{
  display: block;
  color: black;
  margin: 1rem 0 .5rem 0;
}
.industry .content .data .card form input{
  border: 1px solid #CCC;
  padding: .5rem 1rem;
  border-radius: 2rem;
  width: 90%;
}
.industry .content .data .card form .submit-btn{
  padding: .5rem 1rem;
  border-radius: 1rem;
  color: #FFF;
  background-color: #D01B50;
  border: 2px solid #D01B50;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  width: 6rem;
}
.industry .content .data .card form .submit-btn:hover{
  background-color: #AE1542;
  border-color: #AE1542;
}
.industry .content .data .card form .submit-btn:focus{
  background-color: #FFF;
  color: #D01B50;
}
</style>